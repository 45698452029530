<template>
    <div class="page">
      <CandidateReports :userId="$route.params.id" />
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import CandidateReports from '@/components/candidates/profile/CandidateReports';

export default {
  name: 'CandidateReportsPage',
  components: {
    CandidateReports,
  },
  data() {
    return {
    };
  },
  computed: {
    ...mapGetters({
      user: 'auth/user',
    }),
  },
  methods: {
  },
};
</script>

<style scoped lang="scss">
</style>
